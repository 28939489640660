<template>
    <div>
        <SalaryCategories />
    </div>
</template>

<script>

import SalaryCategories from '@/components/admin/salary/SalaryCategories.vue';

export default {
    name: "SalaryList",
    components: { SalaryCategories },
};
</script>

<style scoped></style>