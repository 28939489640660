<template>
    <div class='salary-categories-list'>
        <Preloader v-if='loader' />
        <h2>Категории преподавателей</h2>

        <div class='row mb-3 justify-content-between'>
            <div class='input-group col-4'>
                <input type='search' class='form-control' v-model='search' id='search-input'>

                <span class='input-group-append'>
                        <button type='button' class='btn btn-default' @click='fetchData(search)'>
                            Искать
                        </button>
                    </span>
            </div>

            <div class='col-4 text-right'>
                <button class='button_add' @click.prevent='$router.push({name: "SalaryCreate"})'>
                    Добавить категорию
                </button>
            </div>
        </div>

        <div class='widget'>
            <table class='table table-fix table-hover'>
                <thead class='table_head'>
                <tr>
                    <th>#</th>
                    <th>Название</th>
                    <th>Язык</th>
                    <th class='text-right'>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='item in salaries' :key='item.id'>
                    <td>{{ item.id }}</td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.currency }}</td>

                    <td class='actions_container'>
                        <router-link
                            class='leads_table_action_link btn mr-2 btn-primary'
                            :to="{name: 'SalaryEdit', params:{ id: item.id }}"
                        >
                            <span class='glyphicon glyphicon-edit' />
                        </router-link>
                        <a class='btn mr-2 btn-danger' @click.prevent='deleteModal(item.id, item.title)'>
                            <span class='fa fa-trash-o delete'></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import DeleteModal from '@/components/Modal/DeleteModal.vue';

export default {
    name: 'SalaryCategories',
    components: { Preloader: () => import('@/components/Preloader.vue') },
    data() {
        return {
            loader: false,
            search: null,
            salaries: null,
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData(query = null) {
            try {
                this.loader = true;
                const { data } = await this.$axios.get('crm/salary-categories', { params: { query } });
                this.salaries = data.data;
                this.pagination = data.meta;
                this.loader = false;
            } catch (ex) {
                this.loader = false;
                console.log('cant fetch data: ', ex);
            }
        },
        deleteModal(id, name) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: this.deleteRecord,
                    callback: async () => {
                        await this.fetchData(this.search);
                    },
                }, { name: 'DeleteModal' },
            );
        },
        async deleteRecord(id) {
            try {
                await this.$axios.delete(`crm/salary-categories/${id}`);
                this.$toasted.success('Запись удалена', { position: 'bottom-right' });
            } catch (e) {
                this.$toasted.error(e, { position: 'bottom-right' });
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}

.actions_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.button_add {
    display: inline-block;
    vertical-align: middle;
    border: none;
    font-weight: 400;
    background: #3E86CA;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0 20px;
    font-size: 14px;
    height: 100%;
    transition: .1s;

    &:hover {
        background: #2e6ca7;
        text-decoration: none;
    }
}
</style>